@import '~@angular/material/prebuilt-themes/indigo-pink.css';


body {
  overscroll-behavior: contain;
  width: 100vw;
  overflow-x: hidden;
}

:root {
  --Color1: #4772a7;
  --Color2: #3eb2ed;
}

*{
  outline: none !important;
  font-family: 'Poppins', sans-serif !important;
}

img{
  cursor: pointer !important;
}
/* You can add global styles to this file, and also import other style files */
.App-Text-Color, .App-Text-Style {
    color: black;
}

.App-Text-Style {
    font-size: 45px;
}

.App-Text-Back, .App-Text-Color, .App-Text-Style {
    background-color: blue;
}

.Payliance-Login-Welcome-Text {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 26px;
}

.Payliance-Forgot-header-Text {
    margin-top: 30px;
    font-size: 26px;
}

.Payliance-Primary-Text, .Payliance-Login-Welcome-Text, .Payliance-Forgot-header-Text,.header-Title {
    color: var(--Color1) !important;
}

.header-Title{
    font-size: 20px;
    text-align: center;
    width: 100%;
    z-index: 1;
    height: 5vb;
    background-color: white;
}

.btn-primary {
    width: 100%;
    background-color: var(--Color2)!important;
    border-color: var(--Color2);
    box-shadow: none;
    padding: 7px;
    color: white;
    border-radius: 5px;
    border-style: hidden;
    height: 38px;
}
.btn{
  width: 100%;
  box-shadow: none;
  padding: 7px;
  color: white;
  border-radius: 5px;
  border-style: hidden;
}

.Login-Normal-Text {
    font-size: 14px;
    padding: 0rem 0.3rem;
}

.Login-Normal-Text-With-UnderLine {
    color: var(--Color2) !important;
    font-weight: 700;
    text-decoration: underline;
    font-size: 14px !important;
    cursor: pointer;
}

.Login-Normal-Text-Without-UnderLine {
    color: black;
    font-size: 13px;
    cursor: pointer;
}
.UnderLine {
  text-decoration: underline;
}


.form-control:focus {
box-shadow: none!important;
outline: lightgray!important;
}

.success-toast,
.error-toast {
    background-color: #fff;
    color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cdk-global-overlay-wrapper, .cdk-global-overlay-wrapper > div {
    display: flex;
    justify-content: center !important;
    width: 100%;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface{
    box-shadow: none;
}

.cdk-global-overlay-wrapper > div > mat-snack-bar-container{
    flex: 0 0 auto;
    flex-basis: fit-content;
    padding: 0;
}

.sticky-tabs .mat-mdc-tab-header{
    bottom: 0px;
    position: fixed;
    z-index: 4;
    background: white;
    width: 100%;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
    padding-top: 0.25rem;
  }
  
  .mat-mdc-tab-body-wrapper{
    height: inherit !important;
  }
  .mat-mdc-tab-label-container > div > .mat-mdc-tab-labels {
    height: 98% !important;
  }
  .mat-mdc-tab-label-container > div > .mat-mdc-tab-labels > div {
    height: inherit !important;
  }

  .mdc-tab__text-label{
    line-height: 2 !important;
  }

  .mat-icon img{
    width: 25px;
    }

  .mat-icon{
    width: 40px !important;
    line-height: normal !important;
  }

  .first{
    font-size: 14px;
  }

  @media (max-width: 768px) {
    .mat-icon img{
      width: 25px;
      }

      .first {
        font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .mat-icon img{
    width: 22px;
    }
    .first {
      font-size: 12px;
  }
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 2px; 
  }
  .Account-Tab .mat-mdc-tab-header{
    max-width: 80%;
    margin-left: 10%;
}
::ng-deep .mat-tab-body-wrapper {
    animation: none;
  }

.code-input.red-border span input {
    border-color: red !important;
}
.popup-label-Bold{
    font-size: 26px;
    color: var(--Color2) !important;
}
.code-input span input {
    box-shadow: none !important;
    height: 3.375em !important;
}

.Modalbtns{
    width: 5rem !important;
}

#ICON:focus {
    outline: none !important; 
  }

  .mat-mdc-dialog-surface mdc-dialog__surface{
    overflow: hidden !important;
  }

  .popup-title{
    color: var(--Color1) !important;
    font-weight: bold;
    width: 100%;
    }
  
.a:focus {
    box-shadow: none;
    outline: lightgray;
}

.ContactusCdk .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface{
  border-radius: 0 !important;
}

  
  @media (min-width: 768px) and (max-width:990px)
  {
    .confirmModal {
        background: #fff;
        padding: 16px;
        text-align: center;
        font-size: 15px;
        border-radius: 4px!important;
        z-index: 9999;
        flex-direction: column;
    }
  }
  @media (min-width: 991px) and (max-width:2550px)
  {
    .confirmModal {
        background: #fff;
        padding: 16px;
        text-align: center;
        font-size: 15px;
        border-radius: 4px!important;
        z-index: 9999;
        flex-direction: column;
    }
  }

  .mat-mdc-dialog-container{
    height: inherit !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 20px!important;
  }

  .form-control{
    height: calc(1.5em + 1rem + 15px)!important;
    border: 1px solid lightgray !important;
    font-size: small;
  }

  .customdrodown{
    height: calc(1rem + 34.5px)!important;
    padding: 0px 0px !important;
    display: grid;
    align-items: center;
  }
  .form-control.ng-invalid.ng-dirty:not(.password-field):not(.accountFields){
    box-shadow: none !important;
    border: 1px solid red !important;
    background-image: none !important;
  }
  
  .form-control.is-invalid, .was-validated .form-control:invalid{
    background: none !important;
  }

  .form-control{
    color: black !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important; /* Set the desired background color */
  -webkit-text-fill-color: #333 !important; /* Set the desired text color */
}

.TabData{
  padding-left: 0.8em!important;    
}

@media (min-width: 400px)
{
  .w-25 {
    width: 22%!important;
}

}


.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane{
  margin-top: -43px !important;
  max-width: 545px !important;
  .mat-mdc-select-panel{
    box-shadow: none !important
  }
}

.ConfirmPaymentModalData{
  font-size: 14px !important;
  font-weight: 400 !important;
  display: flex;
  justify-content: space-between !important;
  flex-wrap: nowrap;
}


.ConfirmPaymentModalData > div{
  width: max-content;
  text-align: left;
  overflow-wrap: anywhere;
  align-self: center;
}

@media (min-width: 576px)
{
  .cdk-overlay-pane{
    max-width:545px !important
  }  

  .label-title{
    font-size: 16px !important;
  }

  .HelpPopup{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

@media (min-width: 1024px) {
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    margin-top: -43px!important;
}
}

@media (max-width: 545px)
{
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    margin-top: -43px!important;
}

}

@media (max-width: 420px)
{
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    margin-top: -50px!important;
}
}

@media (max-width: 415px)
{
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    margin-top: -43px!important;
}
}

@media (max-width: 412px) or (max-width: 380px)
{
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    margin-top: -43px!important;
}
}

@media (max-width: 390px) 
{
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    margin-top: -43px!important;
}

.ConfirmPaymentModalData{
  gap: 2rem !important;
}
}

@media (max-width: 372px)
{
  
  .Select-Amount-label{
    margin: 0 !important;
  }
}

@media (max-width: 325px)
{
  
  .Select-Amount-label{
    margin: 0 !important;
  }
}

@media (max-width: 300px)
{

  .Select-Amount-label{
    margin: 0 !important;
  }
}

.w-70{
  width: 70% !important;
}


.account-tabs .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:first-child > span.mdc-tab-indicator > span {
  border-bottom: 2px solid var(--Color2)!important;
  width: 55% !important;
}

.account-tabs .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:nth-child(2) > span.mdc-tab-indicator > span {
  border-bottom: 2px solid var(--Color2)!important;
  width: 25% !important;
}

.AccountHeader{
  background: white !important;
}

@media (max-width: 575px)
{
  .cdk-overlay-pane{
    max-width:88vw !important
  }  

  .AccountHeader.cdk-overlay-pane{
    border-radius: 0;
    max-width:100vw !important
  }  

   .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 10px !important;  
  }
  .AccountsOverlay{
    max-width: 100vw !important;
  }

  .account-tabs .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:first-child > span.mdc-tab-indicator > span {
  width: 75% !important;
}

.account-tabs .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:nth-child(2) > span.mdc-tab-indicator > span {
  width: 35% !important;
}

  .formStyle{
    padding-top: 0.8rem !important;
    padding-bottom: 0.5rem !important;
  }

  .ContactusCdk{
    max-width: 100vw !important;
  }
  .AddPopup,.CardPopup{
    max-width: 100vw !important;
  }
  .contact-popup{
    width: 100vw !important;
  }

  .w-70{
    width: 88% !important;
  }

  .account-popup{
    max-width: 100vw !important;
    width: 100% !important;
  }

  .label-title{
    font-size: 14px !important;
  }
  
  .HelpPopup{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  
}

@media (max-width: 400px)
{
  .account-tabs .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:first-child > span.mdc-tab-indicator > span {
    width: 82% !important;
  }
}

input::placeholder {
  opacity: 0.5 !important
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
  animation-timing-function: initial;
  animation-duration: 3000ms;
  width: fit-content !important;
  max-width: fit-content !important;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-buttons {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

p{
  overflow-wrap: break-word !important;
  cursor: default;
}
.modal-text {
  margin-bottom: 20px;
  font-weight: 600;
}

.confirm-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.confirmModal {
  background: #fff;
  padding: 16px;
  text-align: center;
  font-size: 15px;
  border-radius: 6px!important;
  z-index: 9999;
  flex-direction: column;
  max-width: fit-content !important;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  min-width: 18rem !important;
}

.overlay {
background-color: #000;
position: fixed;
z-index: 999;
opacity: 0.8;
}

.popup-btn{
  width:7.5rem !important
}

.cancel-Btn{
  background-color: #D3D3D3 !important;
  margin-right:1rem;
  border-radius: 6px!important;
  width:8rem !important;
  min-width: 8rem !important;
  height: 38px;
  display: grid;
  align-items: center;
}
.save-Btn {
  border-radius: 6px!important;
  width: 8rem !important;
  min-width: 8rem !important;
  height: 38px;
  display: grid;
  align-items: center;
}

.cancel-Btn , .save-Btn{
  font-size: 14px !important;
}

.btn.focus, .btn:focus, .btn-primary:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0.09em #00000018 !important;
}

.img-cursor{
  cursor: pointer !important;
}

.overflow-hidden{
  overflow: hidden !important;
}

.label-SemiBold-Font-16{
  font-weight: 600;
  font-size: 16px;
}
.label-SemiBold-Font-14{
  font-weight: 600;
  font-size: 14px;
}
.label-Regular-Font-14{
  font-weight: 500;
  font-size: 14px;
}

.Request-Date-Change-Title{
  font-size: 12px;
  text-decoration: underline;
  color: var(--Color2)!important;
  cursor: pointer;
}
.Font-12{
  font-size: 12px !important;
}
.Font-13{
  font-size: 13px !important;
}
.Font-14{
  font-size: 14px !important;
}
.Font-16{
  font-size: 16px !important;
}
.Font-17{
  font-size: 17px !important;
}
.Font-18{
  font-size: 18px !important;
}
.Font-20{
  font-size: 20px !important;
}
.Font-22{
  font-size: 22px !important;
}
.Font-24{
  font-size: 24px !important;
}
.Font-26{
  font-size: 26px !important;
}

/* Styles for the current selected date */
.custom-datepicker .ngb-dp-day.selected {
  background-color: var(--Color1) !important; /* Change the background color as needed */
  color: #ffffff; /* Change the text color as needed */
}

/* Styles for the forward and backward icons */
.custom-datepicker .ngb-dp-arrow {
  color: var(--Color2) !important; /* Change the icon color as needed */
}
.ngb-dp-arrow-next .ngb-dp-navigation-chevron {
  color: var(--Color2) !important;
}
.ngb-dp-navigation-chevron{
  color: var(--Color2) !important;
}
.bg-primary{
  border-radius: 20px !important;
  background-color: var(--Color2) !important;
}

  .mat-mdc-tab{
  font-family: inherit !important;
  font-size: inherit !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  color: black !important;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar
{

  --mdc-tab-indicator-active-indicator-color: var(--Color2)!important;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, .38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, .6);
  --mat-tab-header-active-label-text-color: var(--Color2)!important;
  --mat-tab-header-active-ripple-color: white !important;
  --mat-tab-header-inactive-ripple-color: white!important;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, .6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, .6);
  --mat-tab-header-active-focus-label-text-color: var(--Color2)!important;
  --mat-tab-header-active-hover-label-text-color: var(--Color2)!important;
  --mat-tab-header-active-focus-indicator-color: var(--Color2)!important;
  --mat-tab-header-active-hover-indicator-color: var(--Color2)!important;
}

.mdc-tab-indicator__content{
width: 10rem !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label{
  color: black !important;
  width: 100%;
}
mat-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:not(.mdc-tab--active) {
  opacity: 1 !important;
  color: rgb(131, 126, 126) !important;
}

.account-tabs .mat-mdc-tab-header{
  margin-bottom: 0.5rem !important;
  padding-left: 1rem;
  padding-right: 0.5rem;
  --mdc-tab-indicator-active-indicator-height: 0px;
}

.WebApp-Icon {
  display: inline-block;
  max-width: 18rem;
  min-width: 15rem;
  width: 75vw;
  display: inline-block;
}

@media (max-width: 420px) {
  .cancel-Btn, .save-Btn{
    width:7rem !important;
    min-width: 7rem !important;
  }
  .wp-block-table{
    margin: 0 !important;
  }
}

@media (max-width: 350px) {
  .cancel-Btn, .save-Btn{
    width:6rem !important;
    min-width: 6rem !important;
  }
  .wp-block-table{
    margin: 0 !important;
  }
}

@media (max-width: 300px) {
  .wp-block-table{
    margin: 0 !important;
  }

  .modal-buttons{
    gap: 0.5rem !important;
    display: flex;
  }
}

@media (min-width: 320px) {
 .WebApp-Icon{
  width: 50vw !important;
 }
}
.confirm-amount-popup {
  background-color: #fff;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

@media (max-width: 420px) {
  .HelpBtn{
    max-width: 30vw !important;
  }

  .confirm-amount-popup {
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
  }
 }

@media (min-width: 480px) {
 .WebApp-Icon{
  width: 35vw !important;
 }
}

@media (min-width: 1024px) {
 .WebApp-Icon{
  width: 30vw !important;
 }
}

@media (min-width: 1200px) {
 .WebApp-Icon{
  width: 20vw !important;
 }
}

.toaster-container {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.icon_wrapper{
  border: 1px solid lightgray;
  border-radius: 50%;
  padding: 10px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 1;
  padding: 0;
  width: 25%;
}

.mat-mdc-tab .mdc-tab__content {
  position: relative;
  pointer-events: auto;
  width: 100%;
}

.AccountsOverlay{
  position: fixed !important;
  bottom: 3.5rem !important;
}

.home-icon{
  align-items: center;
  text-align: center;
  width: 100% !important;
  height: auto !important;
}

.form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
}

.mdc-circular-progress__determinate-container, .dashboardSpinner .mdc-circular-progress__indeterminate-container .mdc-circular-progress__spinner-layer .mdc-circular-progress__circle-clipper .mdc-circular-progress__circle-left .mdc-circular-progress__indeterminate-circle-graphic .mdc-circular-progress__indeterminate-circle-graphic, .dashboardSpinner .mdc-circular-progress__indeterminate-container{
    top: 10% !important;
    left: 10% !important;
    transform: rotate(269deg) translate(1.2%,-1.1%)!important;
    width: calc(100% - 35px) !important;
    height: calc(100% - 35px) !important;
}
.calendar-main-Container{
  max-width: 100%;
  text-align: center;
  display: flex;
}
.ngb-dp-weekday {
  width: 3rem; /* Set the width to accommodate 3-character weekdays */
}
button{
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}

.deleteIcon{
  width: 35px!important;
  height: 35px!important;
  background: var(--Color2);
  vertical-align: bottom;
  border-radius: 50%;
}

.is-invalid {
  box-shadow: none !important;
  border: 1px solid red !important;
  background-image: none !important;
}

/* Calendar Styling  */
.ngb-dp-months-select {
  -webkit-appearance: none; /* WebKit browsers */
  -moz-appearance: none; /* Mozilla Firefox */
  appearance: none; /* Supported by most modern browsers */
  background-image: none; /* Remove the default arrow icon */
}
.ngb-dp-months{
  width: 100% !important;
}
.ngb-dp-month{
  width: 100% !important;
}
.ngb-datepicker-month{
  width: 100% !important;
}
.ngb-dp-week{
  width: 100% !important;
}
.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number{
  width: 100% !important;
  color: #000 !important;
  font-style: normal !important;
}
.ngb-dp-day{
  text-align: center !important;
  display: grid;
  justify-content: center;
}
.ngb-dp-header{
  display: none;
  height: 0px !important;
}
.ngb-datepicker input {
  outline: none !important;
}
.ngb-dp-today div{
  background-color : white !important
}
@media (max-height:650px) {
  .Payliance-Login-Welcome-Text{
    margin-top: 10px !important
  }
}

.height-100{
  height: 100vh !important;
}

.account-tabs{
  height: 24vh !important;
  max-height: 24vh !important;
}

.select-account{
  height: 22vh !important;
  max-height: 22vh !important;
}
@media (max-width: 575px) {
  .account-tabs .mat-mdc-tab-header{
    padding-left: 2rem;
    padding-right: 0.5rem;
  }

  .BottomIcon{
    height: 20px !important;
    width: 20px !important;
  }

  .bottom-text {
    font-size: 13px !important;
  }

  .bottom-tab-padding-transaction, .bottom-tab-padding{
    margin-top: 10px !important;
  }
  
} 

.mat-mdc-tab-body-content.scrollbar-invisible{
  width: calc(100% - 5px) !important;
  }
  

.opacity6{
  opacity: 0.6;
 }

 .opacity75{
  opacity: 0.7;
 }

.bottom-navigation-container div{
  width: 100vw !important;
}

.bottom-navigation-container > div > div {
  cursor: pointer;
}

.Acc_Data{
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 1.5rem !important;
}

.Acc_Icon{
  height: 25px !important;
  width: 25px !important;
}

.flex-33{
  flex: 33% !important;
}

.app-logo-color{
  fill: var(--Color1)!important;
}
.btn:hover{
color: #fff;
}

.back-iconAccounts{
  top: 18px !important;
}

.text-danger {
  color: red !important;
}

.Font-Bold{
  font-weight: bold !important;
}

.form-label > b{
  font-size: 14px !important;
}

.AccountFormWrapper{
  margin-top: 4rem !important;
  margin-bottom: 1rem !important;
}

  @media (max-height: 990px)
  {
    .CardPopup{
      margin-bottom: 0rem !important;
    }
  }

  @media (max-height: 830px)
  {
    .AddPopup{
      margin-bottom: 0rem !important;
    }

    .cancel-Btn , .save-Btn{
      font-size: 14px !important;
    }
  }

  @media (max-width: 420px)
  {

    .confirmModal{
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }

  .ErrorText{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  @media (max-width: 360px)
  {
    .w-25 {
      width: 40%!important;
    }

    .confirmModal{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  @media (max-width: 290px)
  {
    .cancel-Btn , .save-Btn{
      width: 5rem!important;
      min-width: 5rem!important;
    }
  }

  .AddPopup .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface{
    overflow-x: hidden !important;
    border-radius: 2px !important;
  }

  .CardPopup .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface{
    overflow-x: hidden !important;
    border-radius: 2px !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
    padding: 0.375rem 0.75rem !important;
    align-items: center !important;
    border-bottom-left-radius: var(--mdc-shape-small, 4px);
    border-bottom-right-radius: var(--mdc-shape-small, 4px);
}

.custom-mat-select{
  font-size: small !important;
  opacity: 0.6 !important;
}

.custom-mat-select.ng-valid{
  opacity: 1 !important;
}

.mat-mdc-form-field-subscript-wrapper{
  display: none !important;
}

.mdc-list-item__primary-text{
  margin: 0 !important;
  padding-top: 1px;
  padding-bottom: 1px;
}

.mat-mdc-select-panel{
padding: 0 !important;
}

.mat-mdc-option{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  min-height: 0 !important;
}



.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: black !important;
  margin-right: 0 !important;
}

.mat-mdc-option.mdc-list-item{
  background: #d3d3d3 !important;
}

.mat-mdc-option.mdc-list-item[id="mat-option-0"] {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.mdc-list-item--selected.mat-mdc-option-active{
  background-color: var(--Color2) !important;
}

.mat-mdc-option .mat-pseudo-checkbox{
  display: none;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple:before{
  border-bottom-color: white !important;
  border-bottom-width: 0 !important
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-underline {
  border-bottom: none;
}

.mat-mdc-form-field-focus-overlay{
  background:white !important;
}


.mdc-text-field--filled .mdc-line-ripple:after{
  border-bottom-color: white !important
}

.mat-mdc-select-arrow{
  width: 22px!important;
  height: 50px !important;
  transform: rotate(270deg);
  top: 5px;
  background: url(./assets/images/top_back_btn.png);
}

.mat-mdc-select-arrow svg{
 display: none;
}


.NoAccountMsg{
  height: inherit !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:before , .mdc-text-field--filled .mdc-line-ripple:after{
  border-bottom-color: lightgray!important;
  border-bottom-width:0 !important
}

.custom-mat-select.ng-invalid > div > div > span.mat-mdc-select-min-line {
  color: black !important;
  opacity: 0.6 !important;
  letter-spacing: normal !important;
}

.cu.custom-mat-select.ng-valid > div > div > span.mat-mdc-select-min-line {
  color: black !important;
  letter-spacing: normal !important;
}

.w-33{
  width: 33% !important;
}

.mat-tab-label:hover {
  background-color: initial; /* Reset the background color on hover */
  color: initial; /* Reset the text color on hover */
}

.overlay-div {
  position: absolute;
  top: 35%;
  left: 0;
  background: lightgray;
  border: 0 !important;
  padding: 0 5px;
  display: inline-table;
  text-align: center;
  z-index: 999;
}

.cls-1 {
  fill: #c4c4c4;
  stroke-width: 0px;
}
.drop-down-icon{
      height: 20px;
      width: 20px;
      transform: rotate(270deg);
     
}
.drop-down-icon-selected{
  background-color: var(--Color2)!important;
  padding: 6px 0px;
  margin: 0px;
  border-radius: 0px;
  width: 100%;
  cursor: pointer;
}
.drop-down-icon-unselected{
  background-color: lightgray;
  padding: 6px 0px;
  width: 100%;
  cursor: pointer;
}
.selected-div-container{
  padding: 6px 12px;
}

.form-control.is-invalid{
  padding-right: 0 !important;
}

  
.activityGroup{
  border: 0px solid #fff;
  border-radius: 0.6rem;
  background: #FFFFFF !important;
  gap: 1rem;
  box-shadow: 0px 0px 7px #292929;
  cursor: pointer;
  padding: 0;
}
.confirm-modal-container-delete-account {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content::-webkit-scrollbar{
  display: none;
}

@media (max-width: 600px) {
  .CardPopup .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface::-webkit-scrollbar{
    display: none;
  }
}

.arrow-right-svg {
  fill: #8282825c;
  margin-left: 4px;
  height: 16px;
}

.Entered-Amount-label {
  font-size: 16px;
  font-weight: bolder;
  margin: 0;
  max-width: 8rem;
  line-break: anywhere;
  text-align: justify;
}

.Select-Amount-label {
  color: white;
  font-size: 14px;
  margin: 0;
  margin-right: 5px;
  cursor: pointer;
}

.Select-Amount-Container {
  background-color: var(--Color2) !important;
  border-radius: 6px !important;
  gap: 0px;
  width: 17rem !important;
  min-width: 10rem !important;
  padding: .375rem .75rem;
  height: 45px;
  cursor: pointer;
}

.Cancel-Container {
  background-color: #D3D3D3 !important;
  margin-right: 1rem;
  border-radius: 6px !important;
  width: 17rem !important;
  min-width: 10rem !important;
  padding: .375rem .75rem;
  height: 45px;
  cursor: pointer;
}

.Cancel-Container *{
 cursor: pointer;
}

.Select-PaymentBtn{
  width: 14rem !important;
}

@media (max-width: 500px){

  .Select-Amount-Container , .Cancel-Container{
    min-width: 8rem !important;
    padding: 0;
  }
  .Select-PaymentBtn{
    width: 10rem !important;
  }
}

@media (max-width: 281px)
{
  .Select-Amount-Container, .Cancel-Container {
    min-width: 6rem!important;
    padding: 0;
}
.Select-PaymentBtn {
  width: 8rem!important;
}
}


.Cancel-label {
  margin-bottom: 0;
  font-size: 14px;
  color: #fff;
}

.Other-Amount-input::placeholder {
  opacity: 1 !important
}

.sanSerif{
  font-family: sans-serif !important;
}

.ErrorModelWrapper{
  max-width: 19rem !important;
  border-radius: 15px!important;
}

.Other-Amount-input::placeholder {
  color: #848484 !important;
  opacity: 1;
}

.Font-wt-500{
  font-weight: 500;
}

.InfoICON{
  width: 20px !important;
  height: 20px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance:textfield;
}

::-ms-reveal {
  display: none;
}

.confirm-amount-popup {
  background-color: #fff;
  padding-left: 1.7rem !important;
  padding-right: 1.7rem !important;
}

.border-btm{
  border-bottom: 1px solid #7b7a7a42;
}

.More-Wrapper{
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-bottom: 1px solid #80808029;
  padding-top: 3rem !important;
}


.Confirm-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--Color1) !important;
}

.TermServiceCdk{
  background-color: #adadad !important;
}

app-terms-service{
  display: flex;
  height: inherit;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.alertBtn{
  border: 0;
  background: white;
  cursor: pointer;
  font-size: 20px!important;
  font-weight: 700 !important;
  color: var(--Color2);
}

.pb-2-5{
  padding-bottom: 0.75rem!important;
}

.AccountsOverlay .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface{
  border-radius: 2px !important;
}

.more-tab-wrapper > div {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.TermsServicePanel .mat-mdc-dialog-container  .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface{
overflow: hidden;
}

.TermsConditionPanel .mat-mdc-dialog-container  .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface{
  overflow: hidden;
  }
  

  .disable-scroll {
    overflow: hidden;
  }
  /* SVG Image */
  .back-image {
    fill: #A1A1A1;
    stroke-width: 0px;
  }
  .back-icon{
    width: 25px !important;
    height: 20px !important;
  }
  .tooltip-svg{
    fill: var(--Color2);
    stroke-width: 0px;
  }
  .delete-main {
    fill: var(--Color2);
  }

  .delete-main, .delete-background {
    stroke-width: 0px;
  }

  .delete-background {
    fill: #fbfdfe;
  }
  .eye-icon {
    fill: #c4c4c4;
    stroke-width: 0px;
  }
  .hide-icon {
    fill: #c5c4c4;
    stroke-width: 0px;
  }
  .Remember-border {
    fill: none;
    stroke: #c4c4c4;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
  .check-icon {
    fill: #c4c4c4;
    stroke-width: 0px;
  }
  .TransactionsOverlay {
    position: fixed!important;
}
  
.disable-scroll {
    overflow: hidden;
  }

 .lightgray-important{
    color: lightgray !important;
  }

  svg{
    cursor: pointer;
  }

  svg.mdc-circular-progress__indeterminate-circle-graphic{
    cursor: default;
  }

  .padding-x{
    padding: 0.375rem 0.75rem;
  }

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track{
    border-radius: 10px !important;
    height: 20px !important;
  }

  .mdc-switch.mdc-switch--selected .mdc-switch__icon , .mdc-switch.mdc-switch--unselected .mdc-switch__icon ,  .mdc-switch .mdc-switch__ripple , .mat-mdc-slide-toggle .mdc-switch:enabled+.mdc-label{
    display: none !important;
  }

  .mdc-switch:enabled .mdc-switch__shadow{
    box-shadow: none !important;
  }

  .mdc-switch__icons{
    background: white !important;
    border-radius: 100% !important;
  }

  .mdc-switch:enabled .mdc-switch__track:before{
    background: var(--Color2);
  }

  .mat-mdc-slide-toggle.mat-accent{
    --mdc-switch-selected-hover-track-color: lightgray !important;
    --mdc-switch-selected-hover-handle-color: var(--Color2) !important;
    --mdc-switch-selected-handle-color: var(--Color2) !important;
  }

  .mdc-switch:enabled .mdc-switch__track:after{
    background: var(--Color2) !important;
  }

  .mdc-switch--selected .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons{
    border: 2px solid var(--Color2) !important;
  }

  .mdc-switch:not(.mdc-switch--selected) .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons , .mdc-switch--unselected .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons{
    border: 2px solid lightgray !important;
  }

  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track:before{
    background: lightgray !important;
  }

  .mdc-switch__handle:before, .mdc-switch__handle:after{
    display: none !important;
  }

  .form-control:disabled{
    background-color: #f9f9f9;
    opacity: 0.5;
  }

  .blackText{
    color: black !important;
  }
  
  .app-loader{
    width: 25px !important;
    height: 25px !important;
  }
  
  .app-loader .mdc-circular-progress__indeterminate-container .mdc-circular-progress__spinner-layer .mdc-circular-progress__circle-clipper svg{
    stroke: black !important;
  }
  @media (max-width: 767px) {
    .app-loader{
      width: 20px !important;
      height: 20px !important;
    }
  }
  
  .loader-spinner {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    animation: rotator 2s cubic-bezier(0.22, 0.61, 0.36, 1) infinite;
    stroke: black;
 }

@keyframes rotator {
  0% { 
  transform: rotate(0deg);
}
  
  100% { 
  transform: rotate(270deg); 
}
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 5s ease-in infinite;
  transition: smooth;
  stroke: #000000;
  stroke-linecap: butt;
  stroke-width: 8px;
}

@keyframes dash {
 0% {
    stroke-dashoffset: 1000;
    transform: rotate(14deg);
  }
}

 .h-90vh{
  height: 90vh;
}

.vertical-separator::before {
  content: "|";
  margin: 0 5px;
  font-size: 16px;
}

.color2{
  stroke: var(--Color2) !important;
}

.button-height{
  height: 38px !important;
}

.disable-scroll::-webkit-scrollbar {
  width: 0px !important;
}

.cdk-global-scrollblock body::-webkit-scrollbar {
  width: 0px !important;
}

span, .defaultCursor{
  cursor: default;
}

.btn-disablecolor {
  color: gray!important;
  cursor: default !important
}
